.bgColor{
    background-color: #513301 !important;
    /* background-color: floralwhite !important; */
}

.l_border{
    border-bottom: 2px dashed lightgray;
}

.box_blur{
    padding: 20px;
    background: rgba(255,255,255,0.05);
    backdrop-filter: blur(5px);
}
/* darkgoldenrod   saddlebrown   floralwhite */

.modal-content {  
    background: transparent !important;
    background: rgba(255,255,255,0.05);
    backdrop-filter: blur(5px);
}

.box1{
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.box2{
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.T_heading{
    color: wheat;
}

.colkerro{
    justify-content:  end !important
}

@media (max-width: 1200px) {
    .colkerro{
        flex-direction: column;
    }
    
}

p{
    font-weight: bold;
}

.colorH{
    color: #ffbb00 !important;
    font-size: x-large;
}

.h-color{
    color: #d8d8d8;
    margin-top: 15px;
}

.btn-border{
    border: 1px solid #ffbb00;
}