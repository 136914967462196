@font-face {
  font-family: "Poppins";
  src: url("./Fonts/Poppins/Poppins-Regular.ttf");
}
@font-face {
  font-family: "Vimland";
  src: url("./Fonts/vimland-cufonfonts-webfont/Vimland-9YmW2.woff");
}
@font-face {
  font-family: "Montserrat_light";
  src: url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap");
}
.stake__button {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
}
.stake__button a {
  font-size: 25px;
  font-weight: bolder;
}
.progressBar__content {
  color: #d8d8d8;
  font-weight: bold;
  font-family: Poppins !important;
  font-size: 25px;
  letter-spacing: 1px;
}
.stake__sm--text {
  color: #ffbb00;
  font-weight: bold;
}
.stake__text {
  font-family: Vimland !important;
  font-size: 25px;
  color: #868585 !important;
}
.main_heading {
  font-family: Vimland !important;
  font-size: 48px;
  color: #ffbb00 !important;
  margin-top: 100px;
}
.vimlandnav {
  font-family: Vimland !important;
  font-size: 20px !important;
  letter-spacing: 1px;
  color: #4caf50 !important;
}
.vimland {
  font-family: Vimland !important;
}

.Montserrat {
  font-family: Montserrat-Light !important;
}
.Poppins {
  font-family: Poppins !important;
}

html {
  scroll-behavior: smooth;
}
body {
  background-color: #352200 !important;
}
.back-color {
  color: #080027 !important;
}
p {
  font-family: Poppins;
  font-size: 16px;
}
h1,
h2,
h3,
h4,
h5 {
  font-family: Vimland;
}

.covers1 {
  transition: all 0.5s;
  opacity: 0;
}
.collapse ul li {
  padding: 5px 10px;
  font-size: large;
}

.collapse ul li a {
  font-size: x-large;
}

nav {
  background-repeat: no-repeat !important;
  background-size: auto;
  background-size: cover;
}
#logo {
  max-width: 70px;
}
#nav-ul {
  list-style: none;
  display: flex;
  justify-content: center;
}
#nav-ul li {
  padding: 5px 545px !important;
  font-weight: 700;
}

#nav-twitter {
  width: 30px;
  padding: 5px;
}
#nav-discord {
  width: 30px;
  padding: 5px;
}

.jc-center {
  justify-content: center;
}
.text-end {
  text-align: end;
}
.nav-link {
  color: black !important;
}
.nav-link {
  font-size: 18px;
}
.welcome-artic-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.what-you-get-box {
  display: flex;
}
.what-you-get-content {
  margin-left: 3rem;
}

@media (max-width: 768px) {
  .welcome-artic-container {
    text-align: center;
  }
  .slide-content {
    display: none !important;
  }
  .silde-inside {
    padding: 10px !important;
  }
  .artic-bx {
    justify-content: center;
  }
  .what-you-get-box {
    display: flex;
    flex-direction: column;
  }
  .what-you-get-content {
    margin-left: 0 !important;
  }
  .connect-wallet-small {
    position: absolute !important;

    right: 25% !important ;
  }

  .roadmap-container .imagediv {
    width: 311px !important;
    height: 406px !important;
    max-width: 411px;
    max-height: 546px;
    height: auto;
  }
  .ai-center-small {
    align-items: center;
  }
  .emailSubmit {
    background-color: white;
    border: none;
    position: relative;
    right: 30px;
  }
  .reward2Section img {
    transform: scale(1.5) !important;
    margin-top: 40px;
    margin-bottom: 30px;
  }
  .as12 {
    margin: 0px 100px;
  }
  .claimLink {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}
@media (max-width: 1008px) {
  .emailSubmit {
    bottom: 26px;
    left: 160px;
  }
  .as12 {
    margin: 0px 100px;
  }
}

@media (max-width: 540px) {
  .react-3d-carousel {
    height: 290px !important;
  }
  .slide-image-box img {
    height: auto !important;
  }
  .silde-inside {
    height: 290px !important;
  }
  .slide-image-box {
    max-height: -webkit-fill-available;
  }
}

/* slider css */
#slider {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.4);
  border-radius: 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.slide {
  width: 100%;

  display: none;
  animation-name: fade;
  animation-duration: 1s;
}
.silde-inside {
  width: 100%;
  height: 390px;
  background-color: white;
  padding: 20px 40px;
}
@keyframes fade {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}

.controls {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.5em;
  padding: 15px 10px;
  border-radius: 5px;
}

.controls:hover {
  background: white;
  transition: 0.3s;
}

.controls:active {
  color: grey;
}

#left-arrow {
  left: 10px;
}

#right-arrow {
  right: 10px;
}

#dots-con {
  text-align: center;
}
.dot {
  display: inline-block;
  background: grey;
  padding: 8px;
  border-radius: 50%;
  margin: 10px 5px;
}

.active {
  background: crimson;
}

@media (max-width: 576px) {
  #slider {
    width: 100%;
  }

  .controls {
    font-size: 1em;
  }

  #dots-con {
    display: none;
  }
}
.slide-heading {
  font-family: Somerton Dense;
  font-style: normal;
  font-weight: normal;
  font-size: 44px;
  line-height: 51px;
  color: #010824 !important;

  /* identical to box height */
  letter-spacing: 0.08em;
}
.slide-image-box {
  border-radius: 20px;
  transform: matrix(-1, 0, 0, 1, 0, 0);
}
.slide-image-box img {
  height: 354px;
  width: 354px;
}
.jc-sb {
  justify-content: space-between;
}
.suvivors-color {
  color: #42c6ff !important;
}
.slide-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.price-Heading {
  font-style: normal;
  font-weight: normal;
  font-size: 44px;
  line-height: 51px;
  font-family: somerton dense;
}
.price-content {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
}
.price-container hr {
  background: gray;
  margin-bottom: 35px;
}
.price-container img {
  width: 117px;
  height: 117px;
  border-radius: 20px;
}
.cernter-vertically {
  display: flex;
  justify-content: center;
  align-items: center;
}
.span-blue {
  color: #42c6ff;
}
.mint-box {
  background-color: white;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}
.mint-box p {
  color: black;
}
.mint-box h3 {
  color: black;
}
.mint-box img {
  max-width: 426px;
  max-height: 376px;
  width: 100%;
  height: auto;
}
.mint-btn {
  background: #42c6ff;
  border-radius: 10px;
  padding: 4px 70px;
}
.roadmap-container {
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
  margin-top: 3.5rem !important;
}
.roadmap-container .imagediv {
  width: 411px;
  height: 546px;
}
.roadmap-container img {
  max-width: 100%;
  height: 100%;
  border-radius: 20px;
}
.ai-inherit {
  align-items: inherit !important;
}
.color-white {
  color: white;
}
.color-black {
  color: black;
}
.lh25 {
  line-height: 25px;
}
.footer-p {
  font: Poppins;
  font-weight: 700;
  font-style: normal;
  font-size: 18px;
  line-height: 27px;
}
footer {
  background-color: white;
}
.footer-img {
  width: 166px;
  height: 166px;
}
.footer-1s-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
/* faq */
.panel-title a {
  color: yellow !important;
}
.panel-title > a:before {
  float: right !important;
  font-family: FontAwesome;
  content: "\f068";
  padding-right: 5px;
}

.panel-title > a.collapsed:before {
  float: right !important;
  content: "\f067";
}
.panel-title > a:hover,
.panel-title > a:active,
.panel-title > a:focus {
  text-decoration: none;
}
.panel-heading {
  padding: 20px 15px;
  border-bottom: 1px solid transparent;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}
.panel-heading a {
  font-size: 16px;
}
.panel-heading:hover a {
  color: yellow !important;
}
.panel {
  margin-bottom: 20px !important;
  background-color: #ffffff;
  border: 1px solid transparent;
  -webkit-box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
  box-shadow: 15px 16px 13px 8px rgb(4 4 4 / 5%);
}
.jumbotron {
  padding-top: 30px;
  padding-bottom: 30px;
  margin-bottom: 30px;
  color: inherit;
  background-color: rgb(255, 187, 0) !important   ;
  text-align: center;
  color: #fff;
}
.faq-ques {
  background-color: #342101;

  border: 1px solid gray;
  border-radius: 5px;
}
.faq-ques a {
  color: white;
}
.panel-body {
  color: white;
  padding: 12px;
}
.color-white {
  color: white !important;
}
/* @font-face {
   font-family: somerton dense;
   src: url(/Fonts/Somerton-Dense/Somerton\ Dense.otf);
} */

.navFont,
.weclomeFont,
#tribes {
  font-family: "Somerton Dense";
  font-style: normal;
  font-weight: normal;
  line-height: 51px;
  letter-spacing: 0.08em;
}
.navFont,
.weclomeFont {
  font-size: large;
}

.section3 {
  font-size: large;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
}

.silde-inside {
  font-size: medium !important;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 800 !important;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.section5 {
  font-family: "Poppins";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}

.section6H2 {
  font-family: "Somerton Dense";
  font-size: 35px;
  font-style: normal;
  font-weight: 600;
  line-height: 51px;
  letter-spacing: 0.2em;
}

.section6P {
  font-family: Poppins;
  line-height: 30px;
  letter-spacing: 0em;
  font-size: 20px;
}

.section6Btn {
  font-family: Poppins;
  line-height: 30px;
  letter-spacing: 0em;
  padding: 5px 50px !important;
}

.section7H1 {
  font-family: "Somerton Dense";
  font-style: normal;
  line-height: 51px;
  letter-spacing: 0.1em;
}

.section7P {
  font-family: Montserrat;
}

.section8H3 {
  font-family: "Somerton Dense";
  font-style: normal;
  line-height: 51px;
  letter-spacing: 0.1em;
}

.section9 {
  font-family: "Somerton Dense";
  font-style: normal;
  line-height: 51px;
  letter-spacing: 0.05em;
}

.footerP {
  font-family: Poppins;
  line-height: 20px;
  letter-spacing: 0em;
}

.sliderImg {
  width: 100%;
  max-height: 350px;
  max-width: 300px !important;
}

.react-3d-carousel .slider-container .slider-left div {
  border-color: transparent !important;
}
.react-3d-carousel .slider-container .slider-right div {
  border-color: transparent !important;
}

.flex-center {
  display: flex;
  justify-content: center;
}
.wrap {
  white-space: pre-wrap; /* CSS3 */
  white-space: -moz-pre-wrap; /* Firefox */
  white-space: -pre-wrap; /* Opera <7 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word;
}
.icon-div {
  position: relative;
  left: 200px;
}
@media (max-width: 1190px) {
  .icon-div {
    position: relative;
    left: 170px;
  }
}
@media (max-width: 1150px) {
  .icon-div {
    position: relative;
    left: 150px;
  }
}
@media (max-width: 1120px) {
  .icon-div {
    position: relative;
    left: 120px;
  }
}
@media (max-width: 992px) {
  .roadmap-container .imagediv {
    margin-bottom: 3rem;
  }
  /* .react-3d-carousel {
  } */
  .toggle-icon-custom {
    flex: 0 0 25%;
    max-width: 25%;
    position: absolute;
    right: 30px;
  }
  .connect-wallet-small {
    position: absolute !important;
    right: 35%;
  }
  .collapse-nav {
    position: relative;
    background-color: white;
    z-index: 10;
  }
  .icon-div {
    display: none;
  }

  .myCard{
    max-height: 300px !important;
  }
  
}
.text-input {
  width: 100% !important;
  margin-bottom: 10px !important;
  margin-top: 10px !important;
}

.makeStyles-paper-2 {
  padding: 0px !important;
}

.pxuyiy {
  padding: 10px !important;
}
.emailSubmit {
  background-color: white;
  border: none;
  position: relative;
  right: 30px;
  color: black;
}

@media (max-width: 992px) {
  .silde-inside {
    padding: 0px;
  }
  .slide-heading {
    font-size: 34px;
    line-height: 33px;
  }
}

/* custom */
.row {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
}

/* Create two equal columns that sits next to each other */
.column {
  flex: 33%;
  padding: 0 4px;
}

.column img {
  margin-top: 8px;
  vertical-align: middle;
}

.column {
  float: left;
  width: auto;
  padding: 0 10px;
}

/* Remove extra left and right margins, due to padding */
.row {
  margin: 0 -5px;
}

/* Clear floats after the columns */
/* .row:after {
  content: "";
  display: table;
  clear: both;
} */

/* Responsive columns */
@media screen and (max-width: 600px) {
    #stakeButtonWrapper{
        flex-direction: column;
    }
    #stakeButtonWrapper .stake__button{
        margin-top: 10px;
    }

  .column {
    width: 100%;
    display: block;
    margin-bottom: 20px;
  }
}

/* Style the counter cards */
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2) !important;
  padding: 16px !important;
  text-align: center !important;
  background-color: #ffbb00 !important;
  border-radius: 25px !important;
  height: 310px !important;
}
.button1 {
  background-color: white;
  color: black;
  border: 2px solid #4caf50; /* Green */
}
.boxModel {
  border: 2px solid #857b6a;
  padding: 20px;

  border-radius: 25px;
}
.box {
  width: 250px;
  height: 50px;
  background-color: #272626;
  color: #fff;
  padding: 20px;
  position: relative;

  text-align: center;
}
.box.arrow-right:after {
  content: "";
  position: absolute;
  right: -15px;
  top: 15px;
  border-top: 15px solid transparent;
  border-right: none;
  border-left: 15px solid grey;
  border-bottom: 15px solid transparent;
}
.card2 {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

  text-align: center;
  background-color: #272626;

  height: 650px;
  width: 300px;
}

* {
  font-family: "Segoe UI";
  font-size: 10pt;
}
.circle {
  position: relative;
  border: 2px solid #999;
  border-radius: 100%;
  width: 50px;
  line-height: 50px;
  text-align: center;
  margin-top: 110px;
  background-color: transparent;
  z-index: 2;
}
.circle:first-child {
  margin-top: 70px !important;
}
.circle:last-child {
  margin-top: 150px !important;
}
.circle:last-child:before {
  position: absolute;
  border: 1px solid #999;
  width: 0;
  height: 150px;
  display: block;
  content: "";
  left: 50%;
  z-index: 1;
  top: -152px;
  margin-left: -1px;
}
.circle:before {
  position: absolute;
  border: 1px solid #999;
  width: 0;
  height: 110px;
  display: block;
  content: "";
  left: 50%;
  z-index: 1;
  top: -112px;
  margin-left: -1px;
}
.circle:first-child:before {
  display: none;
}

.i {
  top: 50%;
}

/* custom */
.jc-center {
  justify-content: center;
}
.d-flex {
  display: flex;
}
.mx-50 {
  margin-top: 60px;
  margin-bottom: 60px;
}
.color-white {
  color: white;
}
.mint-bg {
  background-color: #ffbb00;
}
.mint-bg ul li {
  font-family: poppins;
  font-size: 16px;
}

@media (max-width: 768px) {
  .card {
    height: 230px;
  }
  .as12 {
    margin: 0px !important;
  }
}
.color-yellow {
  color: yellow !important;
}
footer {
  background-color: #342101 !important;
}
.color-white {
  color: white !important;
}
.emailBtn {
  background-color: #272626;
  color: white;
  padding: 14px;
  border: none;
}
.commingSoon {
  background: url(./Images/comming.jpg);
  height: 100% !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.commingContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 100px;
  align-items: center;
  height: 92%;
}
.commingContent h1 {
  font-size: 4rem;
}

.fazool {
  max-width: 400px !important;
  max-height: 400px !important;
}
.fs18 {
  font-size: 22px;
  letter-spacing: 1px;
}

@media (max-width: 992px) {
  .circle {
    margin-top: 140px !important;
  }
  .circle:before {
    top: -140px !important;
    height: 140px !important;
  }
}
@media (max-width: 500px) {
  .boxModel {
    padding: 10px;
  }
  .price-content {
    font-size: 14px;
  }
  .disNone {
    display: none;
  }
  .circle:before {
    display: none;
  }
  .boxModel {
    margin: 10px auto !important;
    width: 100%;
  }
}

@media screen and (max-width: 1500px) and (min-width: 1200px) {
  .as12 {
    margin: 0px 150px !important;
  }
}

@media (max-width: 1200px) {
  .card {
    height: 400px !important;
  }
  .collapse ul li {
    padding: 0;
    font-size: large;
  }
}

@media screen and (max-width: 1200px) and (min-width: 768px) {
  .as12 {
    margin: 0px 100px !important;
  }
  .reward1section {
    margin-top: -10% !important  ;
    position: relative;
  }
}
@media (max-width: 992px) {
  .circle {
    margin-top: 200px;
  }
  .circle:before {
    top: -200px;
    height: 200px;
  }
  .card {
    height: 510px !important;
  }

  .circle:last-child {
    margin-top: 220px !important;
  }
  .circle:last-child:before {
    position: absolute;
    border: 1px solid #999;
    width: 0;
    height: 220px !important;
    display: block;
    content: "";
    left: 50%;
    z-index: 1;
    top: -222px !important;
    margin-left: -1px;
  }

  .fazool {
    max-width: 300px !important;
    max-height: 300px !important;
  }
}
@media (max-width: 768px) {
  .circle:first-child {
    margin-top: 100px !important;
  }
  .circle {
    margin-top: 200px !important;
  }
  .circle:before {
    top: -200px !important;
    height: 200px !important;
  }
  .card {
    height: auto !important;
  }
  .reward1section {
    margin-top: -15% !important  ;
    position: relative;
  }
}
@media (max-width: 576px) {
  .disNone {
    display: none;
  }
  .circle:before {
    display: none;
  }
  .boxModel {
    margin: 10px auto !important;
    width: 100%;
  }
}
@media (max-width: 375px) {
  .circle {
    margin-top: 230px !important;
  }
  .circle:before {
    top: -230px !important;
    height: 230px !important;
  }
}

.reward1section {
  margin-top: -5%;
  position: relative;
}
.reward1section .row {
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 20px;
}

.reward2Section {
  text-align: left;
}
.reward2Section input {
  background-color: #342101 !important;
  color: white !important;
}
.reward2Section input::placeholder {
  color: white;
}

.reward2Section img {
  width: 80px;
  background: rgba(52, 33, 1, 1);
  border-radius: 50%;
  /* border: 4px solid black; */
  position: relative;
  transform: scale(2);
}
.reward2Section img:hover {
  box-shadow: -4px 10px 19px -5px rgb(33 35 38);
}
.reward2Section .mint-bg {
  padding: 25px;
  border-radius: 20px;
}

.howToGetSection {
  text-align: center;
}
.howToGetSection img {
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
}
.howToGetSection .getSecPara {
  color: white;
  font-size: 22px;
  font-weight: 600;
}
.contractAddressSec {
  color: white;
  padding: 20px 10px;
  border: 2px solid rgb(255, 187, 0);
  border-radius: 20px;
  text-align: center;
}
.contractAddressSec a {
  letter-spacing: 1.5px;
  color: white;
  font-weight: 700;
  font-size: 16px;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.contractAddressSec a:hover {
  color: white;
  text-decoration: underline;
}

.claimLink {
  align-items: center;
  height: 100px;
  width: 95px;
  background: rgba(52, 33, 1, 1);
  border-radius: 50%;
  border: 4px solid #ffbb00;
  position: relative;
  display: inline-block;
  transform: scale(2);
}
.claimLink:hover {
  text-decoration: none;
}
.claimLink .claimTextDiv {
  display: flex;
  color: #ffbb00;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}


/* Stake NFT page */
.myCard{
  max-width: 250px;
  max-height: 350px !important;
  margin-bottom: 25px;
}
.myCard img{
  max-width: 200px !important;
  max-height: 250px !important;
}

.stakeAll{
  font-family: Vimland !important;
  font-size: 25px !important;
  color: #ffffff !important;
  margin-top: 100px;

}

.stakeForm{
  background-color: #ffbb00;
  font-size: 100px !important;
  border-radius: 10px;
  padding: 25px;
}

.stakeFormLable{
  font-size: 20px !important;
  font-weight: 600;
  color: #342101;
}

@media (max-width: 1030px) {
  .myC{
    padding: 0px;
  }
  .myCard{
    max-height: 290px !important;
  }
  
}
@media (max-width: 992px) {
  .myC{
    padding: 0px;
  }
  .myCard{
    max-height: 300px !important;
  }
  
}

@media (max-width: 550px) {
  .myC{
    padding: 0px;
  }
  .myCard{
    max-width: 250px;
    max-height: 320px !important;
    margin-bottom: 25px;
  }
  .myCard img{
    max-width: 250px !important;
    max-height: 180px !important;
  }
}
@media (max-width: 420px) {
  .myC{
    padding: 0px;
  }
  .myCard{
    max-width: 200px;
    max-height: 280px !important;
    margin-bottom: 25px;
  }
  .myCard img{
    max-width: 200px !important;
    max-height: 150px !important;
  }
}

@media (max-width: 400px) {
  .myC{
    padding: 0px;
  }
  .myCard{
    max-width: 190px;
    max-height: 270px !important;
    margin-bottom: 25px;
  }
  .myCard img{
    max-width: 150px !important;
    max-height: 150px !important;
  }
}
@media (max-width: 380px) {
  .myC{
    padding: 0px;
  }
  .myCard{
    max-width: 180px;
    max-height: 260px !important;
    margin-bottom: 25px;
  }
  .myCard img{
    max-width: 150px !important;
    max-height: 150px !important;
  }
}
@media (max-width: 365px) {
  .myC{
    padding: 0px;
  }
  .myCard{
    max-width: 175px;
    max-height: 250px !important;
    margin-bottom: 25px;
  }
  .myCard img{
    max-width: 150px !important;
    max-height: 150px !important;
  }
}